import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IAdvancedFilterQuery,
  IDexTransactions,
  IDexTransactionsQuery,
  ISmartMoneyTransactionsPayload,
  ISmartMoneyTransactionsQuery,
  ITokenAuditInfo,
  ITokenDexSummary,
  ITokenDexWhaleTransactionsPayload,
  ITokenDexWhaleTransactionsQuery,
  ITokenGeniusActiveTab,
  ITokenInfo,
  ITokenInfoQuery,
  ITokenMarketInfo,
  ITokenTopAccumulators,
  ITokenTopAccumulatorsPayload,
  ITokenTopAccumulatorsQuery,
  ITokenTopDexTraders,
  ITokenTopDexTradersPayload,
  ITokenTopDexTradersQuery,
  ITokenTopHolders,
  ITokenTopHoldersPayload,
  ITokenTopHoldersQuery,
} from "../../@types/redux";
import { TokenDexTransactionsAPI } from "../../utils/api/TokenDexTransactionsAPI";
import { TokenInfoAPI } from "../../utils/api/TokenInfoAPI";
import { TokenTopAccumulatorsAPI } from "../../utils/api/TokenTopAccumulatorsAPI";
import { TokenTopDexTradersAPI } from "../../utils/api/TokenTopDexTradersAPI";
import { TokenTopHoldersAPI } from "../../utils/api/TokenTopHoldersAPI";

const advancedFilterDefaultQuery = {
  from_ts: null,
  to_ts: null,
  trade_type: "buy",
  trade_token_addresses: [],
  min_main_token_amount: null,
  max_main_token_amount: null,
  min_trade_token_amount: null,
  max_trade_token_amount: null,
  trader_address: "",
  min_amount_usd: null,
  max_amount_usd: null,
  trade_token_list: [],
  apply_filter: false,
} as IAdvancedFilterQuery;

export const tokenDexTxsDefaultQuery = {
  page: 1,
  page_size: 50,
  total: false,
  ordering: "",
  filter_by: null as string | null,
  filter_values: "",
  filter: "",
  filter_logo: "",
  advanced_filter: advancedFilterDefaultQuery,
} as IDexTransactionsQuery;

export const tokenTopHoldersDefaultQuery = {
  page: 1,
  page_size: 25,
};

export const tokenTopAccumulatorsDefaultQuery = {
  page: 1,
  page_size: 20,
  ordering: "",
};

export const tokenTopDexTradersDefaultQuery = {
  page: 1,
  page_size: 20,
};

export const tokenDexWhaleTxsDefaultQuery = {
  page: 1,
  page_size: 50,
  total: false,
  ordering: "",
  threshold: "50000",
};

export const smartMonyTxsDefaultQuery = {
  page: 1,
  page_size: 50,
  total: false,
  ordering: "",
};

const initialState = {
  isRefreshing: false,
  info: {
    loading: false,
    address: "",
    name: "",
    symbol: "",
    created_date: "",
    age: "",
    logo: "",
    bio: "",
    twitter: "",
    website: "",
    telegram: "",
    tags: [] as string[],
    verified: false,
    banner: "",
  },
  market: {
    loading: false,
    price: 0,
    price_ath: 0,
    price_ath_change: 0,
    price_atl: 0,
    price_atl_change: 0,
    price_change_1h: 0,
    price_change_24h: 0,
    price_change_7d: 0,
    price_change_1m: null as number | null,
    price_change_1y: null as number | null,
    market_cap: 0,
    market_cap_change_24h: 0,
    fdv: 0,
    market_cap_fdv_ratio: 0,
    liquidity: 0,
    num_holders: 0,
    num_holders_changed: null as number | null,
  },
  audit: {
    loading: true,
    age: "",
    overall_score: 0,
    creator_address: "",
    owner_address: "",
    total_safe: 0,
    total_risky: 0,
    total_warning: 0,
    details: {} as any,
    top_10_holders: [],
    top_10_percent: 0,
    defi_link: "",
    goplus_link: "",
    tier: "",
  },
  priceHistory: {
    loading: false,
    data: [] as any[],
  },
  currentTokenAddress: "",
  activeTab: "",
  dexTransactions: {
    loading: false,
    total: 0,
    transactions: [],
    summary: {
      total_usd_amount: 0,
      total_token_amount: 0,
      avg_price: 0,
      num_txs: 0,
      loading: false,
    } as ITokenDexSummary,
    query: tokenDexTxsDefaultQuery,
  },
  topHolders: {
    loading: false,
    total_count: 0,
    total_page: 0,
    holders: [],
    query: { ...tokenTopHoldersDefaultQuery },
  },
  topAccumulators: {
    loading: false,
    total_count: 0,
    total_page: 0,
    accumulators: [],
    query: tokenTopAccumulatorsDefaultQuery,
  },
  topDexTraders: {
    loading: true,
    total_count: 0,
    total_page: 0,
    dexTraders: [],
    query: tokenTopDexTradersDefaultQuery,
  },
  dexWhaleTransactions: {
    loading: true,
    total: 0,
    transactions: [],
    query: tokenDexWhaleTxsDefaultQuery,
  },
  smartMoneyTransactions: {
    loading: true,
    total: 0,
    transactions: [],
    query: smartMonyTxsDefaultQuery,
  },
  isNewContract: false,
  isKyberSwapOpened: false,
};

const tokenInfoSlice = createSlice({
  name: "tokenInfo",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<ITokenGeniusActiveTab>) => {
      state.activeTab = action.payload.tab;
    },

    getTokenInfo: (state, action: PayloadAction<ITokenInfoQuery>) => {
      state.info.loading = true;
      state.currentTokenAddress = action?.payload?.token_address;
    },

    setTokenInfo: (state, action: PayloadAction<ITokenInfo>) => {
      state.info.loading = false;

      if (action?.payload) state.info = { ...state.info, ...action?.payload };
    },

    getMarketInfo: (state, action: PayloadAction<ITokenInfoQuery>) => {
      state.market.loading = true;
      state.currentTokenAddress = action?.payload?.token_address;
    },

    setMarketInfo: (state, action: PayloadAction<ITokenMarketInfo>) => {
      state.market.loading = false;

      if (action?.payload)
        state.market = { ...state.market, ...action?.payload };
    },

    getAuditInfo: (state, action: PayloadAction<ITokenInfoQuery>) => {
      state.audit.loading = true;
      state.currentTokenAddress = action?.payload?.token_address;
    },

    setAuditInfo: (state, action: PayloadAction<ITokenAuditInfo>) => {
      state.audit.loading = false;
      if (action?.payload) state.audit = { ...state.audit, ...action?.payload };
    },

    getTokenPriceHistory: (state, _) => {
      state.priceHistory.loading = true;
    },

    setTokenPriceHistory: (state, action: PayloadAction<any>) => {
      state.priceHistory.loading = false;
      state.priceHistory.data = action.payload;
    },

    getDexTransactions: (state, action: PayloadAction<any>) => {
      if (!state.isRefreshing) {
        state.dexTransactions.loading = true;
        state.dexTransactions.transactions = [];
      }
      state.currentTokenAddress = action.payload.token_address as string;
    },

    setDexTransactions: (state, action: PayloadAction<any>) => {
      state.isRefreshing = false;
      state.dexTransactions.transactions = action.payload.transactions;
      state.dexTransactions.total = action.payload.total;
      state.dexTransactions.loading = false;
    },

    getDexTransactionsSummary: (state, _: PayloadAction<any>) => {
      state.dexTransactions.summary.loading = true;
    },

    setDexTransactionsSummary: (
      state,
      action: PayloadAction<ITokenDexSummary>
    ) => {
      state.dexTransactions.total =
        action.payload.num_txs > 10000 ? 10000 : action.payload.num_txs;
      state.dexTransactions.summary = action.payload;
      state.dexTransactions.summary.loading = false;
    },

    setDexTransactionsQuery: (
      state,
      action: PayloadAction<IDexTransactionsQuery>
    ) => {
      state.dexTransactions.query = action.payload;
    },

    resetDexTransactions: (state, _) => {
      state.isRefreshing = true;
      state.dexTransactions.query = { ...tokenDexTxsDefaultQuery };
    },

    setAdvancedFilterQuery: (
      state,
      action: PayloadAction<IAdvancedFilterQuery>
    ) => {
      state.dexTransactions.query.advanced_filter = action.payload;
      state.dexTransactions.query.ordering = "";
      state.dexTransactions.query.filter_by = null;
      state.dexTransactions.query.filter_values = "";
      state.dexTransactions.query.filter = "";
      state.dexTransactions.query.filter_logo = "";
      state.dexTransactions.query.page = 1;
    },

    clearAllFilters: (state, _) => {
      state.dexTransactions.query.advanced_filter = advancedFilterDefaultQuery;
      state.dexTransactions.query.page = 1;
    },

    getTopHolders: (state, _: PayloadAction<ITokenTopHoldersPayload>) => {
      if (!state.isRefreshing) {
        state.topHolders.loading = true;
        state.topHolders.holders = [];
      }
    },

    setTopHolders: (state, action: PayloadAction<ITokenTopHolders>) => {
      state.isRefreshing = false;
      state.topHolders.loading = false;
      state.topHolders.total_count = action.payload.total_count;
      state.topHolders.total_page = action.payload.total_page;
      state.topHolders.holders = action.payload.holders;
    },

    setTopHoldersQuery: (
      state,
      action: PayloadAction<ITokenTopHoldersQuery>
    ) => {
      state.topHolders.query.page_size = action.payload.page_size;
      state.topHolders.query.page = action.payload.page;
    },

    resetTopHolders: (state, _) => {
      state.isRefreshing = true;
      state.topHolders.query = { ...tokenTopHoldersDefaultQuery };
    },

    getTopAccumulators: (
      state,
      _: PayloadAction<ITokenTopAccumulatorsPayload>
    ) => {
      if (!state.isRefreshing) {
        state.topAccumulators.loading = true;
        state.topAccumulators.accumulators = [];
      }
    },

    setTopAccumulators: (
      state,
      action: PayloadAction<ITokenTopAccumulators>
    ) => {
      state.isRefreshing = false;
      state.topAccumulators.loading = false;
      state.topAccumulators.total_count = action.payload.total_count;
      state.topAccumulators.total_page = action.payload.total_page;
      state.topAccumulators.accumulators = action.payload.accumulators;
    },

    setTopAccumulatorsQuery: (
      state,
      action: PayloadAction<ITokenTopAccumulatorsQuery>
    ) => {
      state.topAccumulators.query.page_size = action.payload.page_size;
      state.topAccumulators.query.page = action.payload.page;
      state.topAccumulators.query.ordering = action.payload.ordering;
    },

    resetTopAccumulators: (state, _) => {
      state.isRefreshing = true;
      state.topAccumulators.query = { ...tokenTopAccumulatorsDefaultQuery };
    },

    getTopDexTraders: (state, _: PayloadAction<ITokenTopDexTradersPayload>) => {
      if (!state.isRefreshing) {
        state.topDexTraders.loading = true;
        state.topDexTraders.dexTraders = [];
      }
    },

    setTopDexTraders: (state, action: PayloadAction<ITokenTopDexTraders>) => {
      state.isRefreshing = false;
      state.topDexTraders.loading = false;
      state.topDexTraders.total_count = action.payload.total_count;
      state.topDexTraders.total_page = action.payload.total_page;
      state.topDexTraders.dexTraders = action.payload.traders;
    },

    setTopDexTradersQuery: (
      state,
      action: PayloadAction<ITokenTopDexTradersQuery>
    ) => {
      state.topDexTraders.query.page_size = action.payload.page_size;
      state.topDexTraders.query.page = action.payload.page;
    },

    resetTopDexTradersQuery: (state, _) => {
      state.isRefreshing = true;
      state.topDexTraders.query = { ...tokenTopDexTradersDefaultQuery };
    },

    getDexWhaleTransactions: (
      state,
      action: PayloadAction<ITokenDexWhaleTransactionsPayload>
    ) => {
      if (!state.isRefreshing) {
        state.dexWhaleTransactions.loading = true;
        state.dexWhaleTransactions.transactions = [];
      }
      state.currentTokenAddress = action.payload.token_address as string;
    },

    setDexWhaleTransactions: (
      state,
      action: PayloadAction<IDexTransactions>
    ) => {
      state.isRefreshing = false;
      state.dexWhaleTransactions.loading = false;
      state.dexWhaleTransactions.transactions = action.payload
        .transactions as any;
      state.dexWhaleTransactions.total = action.payload.total;
    },

    setDexWhaleTransactionsQuery: (
      state,
      action: PayloadAction<ITokenDexWhaleTransactionsQuery>
    ) => {
      state.dexWhaleTransactions.query.page_size = action.payload.page_size;
      state.dexWhaleTransactions.query.page = action.payload.page;
      state.dexWhaleTransactions.query.threshold = action.payload.threshold;
    },

    resetDexWhaleTransactions: (state, _) => {
      state.isRefreshing = true;
      state.dexWhaleTransactions.query = {
        ...tokenDexWhaleTxsDefaultQuery,
        threshold: state.dexWhaleTransactions.query.threshold,
      };
    },

    getSmartMoneyTransactions: (
      state,
      action: PayloadAction<ISmartMoneyTransactionsPayload>
    ) => {
      if (!state.isRefreshing) {
        state.smartMoneyTransactions.loading = true;
        state.smartMoneyTransactions.transactions = [];
      }
      state.currentTokenAddress = action.payload.token_address as string;
    },

    setSmartMoneyTransactions: (
      state,
      action: PayloadAction<IDexTransactions>
    ) => {
      state.isRefreshing = false;
      state.smartMoneyTransactions.loading = false;
      state.smartMoneyTransactions.transactions = action.payload
        .transactions as any;
      state.smartMoneyTransactions.total = action.payload.total;
    },

    setSmartMoneyTransactionsQuery: (
      state,
      action: PayloadAction<ISmartMoneyTransactionsQuery>
    ) => {
      state.smartMoneyTransactions.query.page_size = action.payload.page_size;
      state.smartMoneyTransactions.query.page = action.payload.page;
    },

    resetSmartMoneyTransactions: (state, _) => {
      state.isRefreshing = true;
      state.dexWhaleTransactions.query = {
        ...tokenDexWhaleTxsDefaultQuery,
        threshold: state.dexWhaleTransactions.query.threshold,
      };
    },

    setIsNewContract: (state, action: PayloadAction<boolean>) => {
      state.isNewContract = action.payload;
    },

    setKyberSwapOpened: (state, action: PayloadAction<boolean>) => {
      state.isKyberSwapOpened = action.payload;
    },
  },
});

const tokenInfoActions = tokenInfoSlice.actions;
const tokenInfoReducer = tokenInfoSlice.reducer;

export { tokenInfoActions, tokenInfoReducer };

// Sagas

function* getTokenInfo(action: PayloadAction<ITokenInfoQuery>): any {
  const res = yield call(TokenInfoAPI.getTokenInfo, {
    ...action.payload,
  });
  if (!res.error) yield put(tokenInfoActions.setTokenInfo(res.data));
}

function* getMarketInfo(action: PayloadAction<ITokenInfoQuery>): any {
  const res = yield call(TokenInfoAPI.getMarketInfo, {
    ...action.payload,
  });
  if (!res.error) yield put(tokenInfoActions.setMarketInfo(res.data));
}

function* getAuditInfo(action: PayloadAction<ITokenInfoQuery>): any {
  const res = yield call(TokenInfoAPI.getAuditInfo, {
    ...action.payload,
  });
  if (!res.error) yield put(tokenInfoActions.setAuditInfo(res.data));
}

function* getTokenPriceHistory(action: PayloadAction<ITokenInfoQuery>): any {
  const res = yield call(TokenInfoAPI.getTokenPriceHistory, {
    ...action.payload,
  });
  if (!res.error) yield put(tokenInfoActions.setTokenPriceHistory(res.data));
  else yield put(tokenInfoActions.setTokenPriceHistory([]));
}

function* getDexTransactions(action: any): any {
  const res = yield call(
    TokenDexTransactionsAPI.getTokenDexTransactions as any,
    {
      ...action.payload,
    }
  );

  if (!res.error) {
    yield put(
      tokenInfoActions.setDexTransactions({
        transactions: res.data.results,
        total: res.data.total_count,
      })
    );
  } else {
    yield put(
      tokenInfoActions.setDexTransactions({
        transactions: [],
        total: 0,
      })
    );
  }
}

function* getDexTransactionsSummary(action: any): any {
  const res = yield call(
    TokenDexTransactionsAPI.getTokenDexTransactions as any,
    {
      ...action.payload,
    }
  );

  yield put(tokenInfoActions.setDexTransactionsSummary(res.data));
}

function* getTopHolders(action: PayloadAction<ITokenTopHoldersPayload>): any {
  const res = yield call(TokenTopHoldersAPI.getTopHolders as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      tokenInfoActions.setTopHolders({
        ...res.data,
      })
    );
  } else {
    yield put(
      tokenInfoActions.setTopHolders({
        total_count: 0,
        total_page: 0,
        holders: [],
      })
    );
  }
}

function* getTopAccumulators(
  action: PayloadAction<ITokenTopAccumulatorsPayload>
): any {
  const res = yield call(TokenTopAccumulatorsAPI.getTopAccumulators as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      tokenInfoActions.setTopAccumulators({
        ...res.data,
      })
    );
  } else {
    yield put(
      tokenInfoActions.setTopAccumulators({
        accumulators: [],
        total_count: 0,
        total_page: 0,
      })
    );
  }
}

function* getTopDexTraders(
  action: PayloadAction<ITokenTopDexTradersPayload>
): any {
  const res = yield call(TokenTopDexTradersAPI.getTopDexTraders as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      tokenInfoActions.setTopDexTraders({
        ...res.data,
      })
    );
  } else {
    yield put(
      tokenInfoActions.setTopDexTraders({
        traders: [],
        total_count: 0,
        total_page: 0,
      })
    );
  }
}

function* getDexWhaleTransactions(
  action: PayloadAction<ITokenDexWhaleTransactionsPayload>
): any {
  const dexTransactionsRes = yield call(
    TokenDexTransactionsAPI.getTokenDexWhaleTransactions as any,
    {
      ...action.payload,
    }
  );

  if (!dexTransactionsRes.error) {
    yield put(
      tokenInfoActions.setDexWhaleTransactions({
        transactions: dexTransactionsRes.data.results,
        total: dexTransactionsRes.data.total_count,
      })
    );
  } else {
    yield put(
      tokenInfoActions.setDexWhaleTransactions({
        transactions: [],
        total: 0,
      })
    );
  }
}

function* getSmartMoneyTransactions(
  action: PayloadAction<ISmartMoneyTransactionsPayload>
): any {
  const smartMoneyRes = yield call(
    TokenDexTransactionsAPI.getSmartMoneyTransactions as any,
    {
      ...action.payload,
    }
  );

  if (!smartMoneyRes.error) {
    yield put(
      tokenInfoActions.setSmartMoneyTransactions({
        transactions: smartMoneyRes.data.results,
        total: smartMoneyRes.data.total_count,
      })
    );
  } else {
    yield put(
      tokenInfoActions.setSmartMoneyTransactions({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* tokenInfoSaga() {
  yield all([
    takeLatest(tokenInfoActions.getTokenInfo, getTokenInfo),
    takeLatest(tokenInfoActions.getMarketInfo, getMarketInfo),
    takeLatest(tokenInfoActions.getAuditInfo, getAuditInfo),
    takeLatest(tokenInfoActions.getTokenPriceHistory, getTokenPriceHistory),
    takeLatest(tokenInfoActions.getDexTransactions, getDexTransactions),
    takeLatest(
      tokenInfoActions.getDexTransactionsSummary,
      getDexTransactionsSummary
    ),
    takeLatest(tokenInfoActions.getTopHolders, getTopHolders),
    takeLatest(tokenInfoActions.getTopAccumulators, getTopAccumulators),
    takeLatest(tokenInfoActions.getTopDexTraders, getTopDexTraders),
    takeLatest(
      tokenInfoActions.getDexWhaleTransactions,
      getDexWhaleTransactions
    ),
    takeLatest(
      tokenInfoActions.getSmartMoneyTransactions,
      getSmartMoneyTransactions
    ),
  ]);
}
