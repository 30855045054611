import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IDexTransactions,
  IDexTransactionsQuery,
  IWalletDexTransactionsPayload,
} from "../../@types/redux";
import { WalletsAPI } from "../../utils/api/WalletsAPI";

const initialState = {
  isRefreshing: false,
  tradedTokens: [] as ITradedToken[],
  selectedToken: undefined as ITradedToken | undefined,
  dexTransactions: {
    query: {
      ordering: "",
      page: 1,
      page_size: 50,
      filter_by: "",
      filter_values: "",
      filter: "",
      filter_logo: "",
    },
    wallet_address: "",
    total: 0,
    transactions: [],
    loading: false,
  },
};

interface ITradedToken {
  address: string;
  symbol: string;
  name: string;
  realized_pnl: number;
}

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setTradedTokens: (state, action: PayloadAction<ITradedToken[]>) => {
      state.tradedTokens = action.payload;
    },
    setSelectedToken: (
      state,
      action: PayloadAction<ITradedToken | undefined>
    ) => {
      state.selectedToken = action.payload;
    },
    setRefreshing: (state, action: PayloadAction<boolean>) => {
      state.isRefreshing = action.payload;
    },

    getList: (state, action: PayloadAction<IWalletDexTransactionsPayload>) => {
      if (!state.isRefreshing) {
        state.dexTransactions.loading = true;
        state.dexTransactions.transactions = [];
      }
      state.dexTransactions.wallet_address = action.payload.wallet_address;
    },

    setList: (state, action: PayloadAction<IDexTransactions>) => {
      state.isRefreshing = false;
      state.dexTransactions.loading = false;
      state.dexTransactions.total = action.payload.total;
      state.dexTransactions.transactions = action.payload.transactions;
    },

    setQuery: (state, action: PayloadAction<IDexTransactionsQuery>) => {
      state.dexTransactions.query.page_size = action.payload.page_size;
      state.dexTransactions.query.page = action.payload.page;
      state.dexTransactions.query.ordering = action.payload.ordering as string;
      state.dexTransactions.query.filter_by = action.payload
        .filter_by as string;
      state.dexTransactions.query.filter_values = action.payload.filter_values;
      state.dexTransactions.query.filter = action.payload.filter;
      state.dexTransactions.query.filter_logo = action.payload.filter_logo;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.dexTransactions.query = initialState.dexTransactions.query;
    },
  },
});

export const walletActions = walletSlice.actions;
export const walletReducer = walletSlice.reducer;

// Sagas

function* getDexTransactions(action: any): any {
  const dexTransactionsRes = yield call(WalletsAPI.getWalletDexTransaction, {
    ...action.payload,
  });

  if (!dexTransactionsRes.error) {
    yield put(
      walletActions.setList({
        transactions: dexTransactionsRes.data.results,
        total: dexTransactionsRes.data.total_count,
      })
    );
  } else {
    yield put(
      walletActions.setList({
        transactions: [],
        total: 0,
      })
    );
  }
}

export function* walletSaga() {
  yield all([takeLatest(walletActions.getList, getDexTransactions)]);
}
